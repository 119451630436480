const INIT_STATE = {
  allOccupation: [],
  profileValue: null,
  spinner: false,
  success: false,
  error: false,
  textMessage: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getOccupations_SUCCESS": {
      return {
        ...state,
        allOccupation: action.payload.Record,
      };
    }
    case "getOccupations_FAILURE": {
      return {
        ...state,
        allOccupation: [],
      };
    }
    case "getConditions_SUCCESS": {
      return {
        ...state,
        conditionListSelect: action.payload.Record,
      };
    }
    case "getSPProfile_SUCCESS": {
      return {
        ...state,
        profileValue: action.payload.Record,
        successFetch: true,
        errorFetch: false,
      };
    }
    case "getSPProfile_FAILURE": {
      return {
        ...state,
        profileValue: null,
        successFetch: false,
        errorFetch: true,
      };
    }
    case "EditRecordSpecialist_SUCCESS": {
      return {
        ...state,
        success: true,
        error: false,
        spinner: false,
        textMessage: "Profile Update",
      };
    }
    case "EditRecordSpecialist_FAILURE": {
      return {
        ...state,
        success: false,
        error: true,
        spinner: false,
        textMessage: "Error Occur Try again Later",
      };
    }
    case "StartSpinner_START": {
      return {
        ...state,
        spinner: true,
      };
    }
    case "REST_STATE": {
      return {
        ...state,
        spinner: false,
        success: false,
        error: false,
        textMessage: "",
        successFetch: false,
        errorFetch: false,
      };
    }

    default:
      return state;
  }
};
