import React from "react";
import { Menu } from "antd";
import SidebarLogo from "./SidebarLogo";
import { Link, useLocation } from "react-router-dom";
import MenuList from "./Menu";
const SubMenu = Menu.SubMenu;
const SidebarContent = () => {
  const location = useLocation();
  const { pathname } = location;
  const role = "specialist";
  const MenuData = MenuList[role];
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[0];

  const singleMenuItem = (Item) => {
    return (
      <Menu.Item key={Item.url}>
        <Link
          to={{ pathname: Item.url, state: { key: Item.key } }}
          key={Item.url + "key"}
        >
          <i
            className={`icon icon-${Item.icon}`}
            style={{
              top: Item.top,
              position: "relative",
            }}
          />
          <span>
            {Item.title} {Item.badges ? console.log("=====") : ""}
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const renderSubSubMenu = (Item) => {
    return (
      <SubMenu
        popupClassName="nested-sub-menu"
        key={Item.key}
        title={
          <span>
            <i className={`icon icon-${Item.icon}`} />
            <span>{Item.title} </span>
          </span>
        }
      >
        {Item.subMenu.map((x) => {
          return singleMenuItem(x);
        })}
      </SubMenu>
    );
  };

  const renderSubMenu = (Item) => {
    return (
      <SubMenu
        key={Item.key}
        title={
          <span>
            <i className={`icon icon-${Item.icon}`} />
            <span>{Item.title} </span>
          </span>
        }
      >
        {Item.subMenu.map((x) => {
          if (x.subMenu) {
            return renderSubSubMenu(x);
          } else {
            return singleMenuItem(x);
          }
        })}
      </SubMenu>
    );
  };

  const renderMenu = () => {
    if (MenuData && MenuData.length > 0) {
      return MenuData.map((Item) => {
        if (Item.subMenu) {
          return renderSubMenu(Item);
        } else {
          return singleMenuItem(Item);
        }
      });
    }
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className="gx-mt-4">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={["/" + selectedKeys]}
            theme="dark"
            mode="inline"
          >
            {renderMenu()}
          </Menu>
        </div>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
