import React from "react";
import Layout from "components/LayoutForPortal";
import SpecialistImage from "../assets/images/p-2.png";
const UnderConstruction = () => {
  return (
    <Layout>
      <div
        style={{
          height: "100vh",
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
        }}
        className="gx-d-flex gx-flex-column gx-justify-content-center gx-align-items-center"
      >
        <img width="400px" src={SpecialistImage} alt="" />
      </div>
    </Layout>
  );
};

export default UnderConstruction;
