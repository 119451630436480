const MenuList = {
  specialist: [
    {
      title: "Dashboard",
      url: "/dashboard",
      icon: "dasbhoard",
      top: "4px",
      permissionKey: "dashboard",
      key: "superAdmin",
    },
    {
      title: "Profile",
      url: "/profile",
      icon: "profile",
      top: "4px",
      permissionKey: "profile",
      key: "profile",
    },
    {
      title: "Setting",
      url: "/setting",
      icon: "setting",
      top: "4px",
      permissionKey: "setting",
      key: "setting",
    },
  ],
};

export default MenuList;
