import React from "react";
import { Route, Switch } from "react-router-dom";
import UnderConstruction from "./UnderConstruction";
import Profile from "./Profile";
import Setting from "./Setting";
const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        exact
        path={`${match.url}dashboard`}
        component={UnderConstruction}
      />
      <Route
        exact
        path={`${match.url}profile`}
        component={Profile}
      />
      <Route exact path={`${match.url}setting`} component={Setting} />
    </Switch>
  </div>
);

export default App;
