import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import MainApp from "./MainApp";
import LoginPage from "../../routes/Auth/login";
import SignUp from "../../routes/Auth/phoneNumber";
import StaticPage from "../../components/Auth/StaticPage";
import { setInitUrl } from "redux/actions/CommonHttp";
import SetNewPass from "../../routes/Auth/setNewPassword";
import ForgetPassword from "../../routes/Auth/forgetPassword";
const RestrictedRoute = ({
  component: Component,
  location,
  token,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const dispatch = useDispatch();
  const { token, initURL } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
  }, [dispatch, initURL, location.pathname, location.search]);

  useEffect(() => {
    if (location.pathname === "/") {
      if (token === null) {
        history.push("/login");
      } else if (initURL === "" || initURL === "/" || initURL === "/home") {
        history.push("/login");
      } else {
        history.push(initURL);
      }
    }
  }, [token, initURL, location, history]);

  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/termAndConditions" component={StaticPage} />
      <Route exact path="/privacyPolicy" component={StaticPage} />
      <Route exact path="/signup/:token" component={SignUp} />
      <Route exact path="/setNewPassword/:query" component={SetNewPass} />
      <Route exact path="/forgetPassword" component={ForgetPassword} />
      <RestrictedRoute
        path={`${match.url}`}
        token={token}
        location={location}
        component={MainApp}
      />
    </Switch>
  );
};

export default memo(App);
