import React, { useEffect, useState } from "react";
import Layout from "components/LayoutForPortal";
import SpecialistForm from "./components/SpecialistForm";
import { Form, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction, DataGetAction } from "redux/actions/CommonHttp";
const Index = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const specialistProps = useSelector((state) => state.SpecPro);
  const {
    profileValue,
    success,
    error,
    textMessage,
    successFetch,
    errorFetch,
  } = specialistProps;
  const initialValues = profileValue !== null ? true : false;
  const [disableInput, setDisableInput] = useState(initialValues);
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const [location, setLocation] = useState({
    imageUrl: "",
    loading: false,
  });

  if (success) {
    dispatch({
      type: "REST_STATE",
    });
    setDisableInput(true);
    message.success(textMessage);
  } else if (error) {
    dispatch({
      type: "REST_STATE",
    });
    setDisableInput(false);
    message.error(textMessage);
  }
  if (successFetch) {
    dispatch({
      type: "REST_STATE",
    });
    if (profileValue !== null) {
      setDisableInput(true);
    }
    if (profileValue && profileValue.profileImageUrl) {
      photoList.imageUrl = profileValue.profileImageUrl;
    }
    if (profileValue && profileValue.locationImage) {
      location.imageUrl = profileValue.locationImage;
    }
  } else if (errorFetch) {
    dispatch({
      type: "REST_STATE",
    });
    setDisableInput(false);
  }

  const onSubmit = (values) => {
    values.profileImageUrl = photoList && photoList.imageUrl;
    values.locationImage = location && location.imageUrl;
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProfileInfo",
        "EditRecordSpecialist",
        values,
        "StartSpinner",
        "",
        ""
      )
    );

    if (values && values.fullName) {
      let userInformation = localStorage.getItem("userInfo");
      userInformation.fullName = values.fullName;
      localStorage.setItem(userInformation);
    }
  };

  const fetchList = () => {
    dispatch(
      DataGetAction("getSPProfile", "getSPProfile", { query: "all" }, "")
    );
  };
  useEffect(fetchList, []);

  return (
    <Layout>
      <div className="specialList-Card">
        <div className="specialList-card-header  gx-d-flex gx-align-items-center gx-justify-content-between">
          <span className="gx-text-white">Profile</span>
          {disableInput && (
            <span
              style={{
                border: "1px solid white",
                borderRadius: "8px",
                padding: "10px 10px 5px 10px",
              }}
              onClick={() => setDisableInput(false)}
              className="icon icon-edit gx-link  gx-mx-3 gx-text-white"
            ></span>
          )}
        </div>
        {profileValue === null && <Spin></Spin>}
        {profileValue !== null && (
          <div>
            <Form
              form={form}
              layout="vertical"
              onFinish={onSubmit}
              initialValues={profileValue}
            >
              <SpecialistForm
                profileValue={profileValue}
                form={form}
                photoList={photoList}
                setPhotoState={setPhotoState}
                location={location}
                setLocation={setLocation}
                disableInput={disableInput}
                setDisableInput={setDisableInput}
              />
            </Form>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Index;
