import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Select, Button, Spin } from "antd";
import ImageUploader from "components/AvatarUploader";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DataGetAction } from "redux/actions/CommonHttp";
import Uploader from "components/AvatarUploader/UploadLocation";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const FAQForm = ({
  photoList,
  setPhotoState,
  location,
  setLocation,
  disableInput,
  setDisableInput,
  profileValue,
}) => {
  const dispatch = useDispatch();
  const [SecondaryArray, setSecondaryArray] = useState([]);
  const [PrimaryId, setPrimaryId] = useState("");
  const specialistProps = useSelector((state) => state.SpecPro);
  const { allOccupation, conditionListSelect, spinner } = specialistProps;
  const fetchList = () => {
    dispatch(
      DataGetAction("getOccupations", "getOccupations", { query: "all" }, "")
    );
    dispatch(
      DataGetAction("getConditions", "getConditions", { query: "all" }, "")
    );
    dispatch(
      DataGetAction("getSPProfile", "getSPProfile", { query: "all" }, "")
    );
  };

  useEffect(fetchList, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  useEffect(() => {
    if (profileValue) {
      if (profileValue && profileValue.conditionKnowledgeBase) {
        setSecondaryArray(profileValue.conditionKnowledgeBase);
      }
      if (profileValue && profileValue.conditionKnowledgeBasePrimary) {
        setPrimaryId(profileValue.conditionKnowledgeBasePrimary);
      }
    }
  }, []);

  return (
    <div>
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="fullName"
                label={"Full Name"}
                rules={[
                  {
                    required: true,
                    message: "full name is required",
                  },
                ]}
              >
                <Input disabled={disableInput} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="phoneNumber"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: "Phone is required",
                  },
                ]}
              >
                <Input disabled={disableInput} />
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "email is required",
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="location" label="Location">
                <Input disabled={disableInput} />
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="website" label="Website">
                <Input disabled={disableInput} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="highestDegreeLicense"
                label="High Degree / License"
                rules={[
                  {
                    required: true,
                    message: "High Degree / License is required",
                  },
                ]}
                initialValue=""
              >
                <Input disabled={disableInput} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="association" label="Association">
                <Input disabled={disableInput} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="insuranceAccepted"
                label="Insurance Accepted"
                initialValue=""
              >
                <Input disabled={disableInput} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col
          xl={8}
          lg={8}
          md={8}
          sm={24}
          xs={24}
          className="gx-d-flex gx-align-items-center gx-justify-content-center"
        >
          <ImageUploader
            disableInput={disableInput}
            photoList={photoList}
            setPhotoState={setPhotoState}
          />
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="gx-px-3"
      >
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item name="facebook" label="Facebook">
            <Input disabled={disableInput} />
          </Form.Item>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item name="twitter" label="Twitter">
            <Input disabled={disableInput} />
          </Form.Item>
        </Col>

        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item name="youtube" label="Youtube">
            <Input disabled={disableInput} />
          </Form.Item>
        </Col>
      </Row>
      <Row className="gx-flex-row gx-mx-3">
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item name="linkedIn" label="Linkedin" initialValue="">
            <Input disabled={disableInput} />
          </Form.Item>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item name="instagram" label="Instagram" initialValue="">
            <Input disabled={disableInput} />
          </Form.Item>
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="gx-px-3"
      >
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="specialties"
            label="Specialties"
            rules={[
              {
                required: true,
                message: "Please enter Specialties",
              },
            ]}
          >
            <Select disabled={disableInput} mode="multiple" showSearch>
              {allOccupation &&
                allOccupation.map((item) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.occupationName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            extra={
              <span style={{ color: "#9b9b9b" }}>
                *This means that you have treated at least one patient/client
                with this condition.
              </span>
            }
            name="conditionKnowledgeBasePrimary"
            label={"Condition Knowledge Base (Primary)"}
            rules={[
              {
                required: true,
                message: "Please enter condition knowledge base primary",
              },
            ]}
          >
            <Select onChange={(e) => setPrimaryId(e)} disabled={disableInput}>
              {conditionListSelect &&
                conditionListSelect.map((item) => {
                  if (!SecondaryArray.includes(item.id)) {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item.name}
                      </Option>
                    );
                  }
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            extra={
              <span style={{ color: "#9b9b9b" }}>
                *This means that you have treated at least one patient/client
                with this condition.
              </span>
            }
            name="conditionKnowledgeBase"
            label={"Condition Knowledge Base (Secondary)"}
            rules={[
              {
                required: true,
                message: "Please enter condition knowledge base Secondary",
              },
            ]}
          >
            <Select
              onChange={(e) => setSecondaryArray(e)}
              disabled={disableInput}
              mode="multiple"
              showSearch
            >
              {conditionListSelect &&
                conditionListSelect.map((item) => {
                  if (PrimaryId !== item.id) {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item.name}
                      </Option>
                    );
                  }
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-5">
          <Form.List name="education" className="p-0">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Education" : ""}
                    required={false}
                    key={field.key}
                    className="p-0"
                  >
                    <Form.Item
                      className="p-0"
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input
                        disabled={disableInput}
                        multiple
                        style={{ width: "60%" }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button gx-ml-2"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-py-0 gx-w-100"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%", marginTop: "20px" }}
                    icon={<PlusOutlined />}
                    disabled={disableInput}
                  >
                    Add Education
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-5">
          <Form.List name="experience" className="p-0">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Experience" : ""}
                    required={false}
                    key={field.key}
                    className="p-0"
                  >
                    <Form.Item
                      className="p-0"
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input
                        disabled={disableInput}
                        multiple
                        style={{ width: "60%" }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button gx-ml-2"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-py-0 gx-w-100"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%", marginTop: "20px" }}
                    icon={<PlusOutlined />}
                    disabled={disableInput}
                  >
                    Add Experience
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-5">
          <Form.List name="language" className="p-0">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Language" : ""}
                    required={false}
                    key={field.key}
                    className="p-0"
                  >
                    <Form.Item
                      className="p-0"
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input
                        disabled={disableInput}
                        multiple
                        style={{ width: "60%" }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button gx-ml-2"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    className="gx-py-0 gx-w-100"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%", marginTop: "20px" }}
                    icon={<PlusOutlined />}
                    disabled={disableInput}
                  >
                    Add language
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Uploader
            disabled={disableInput}
            location={location}
            setLocation={setLocation}
          />
        </Col>
      </Row>

      {!disableInput && (
        <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
          <Form.Item>
            <Button
              onClick={() => setDisableInput(true)}
              className="gx-py-0  gx-my-3"
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            {spinner && (
              <Spin>
                <Button
                  type="primary"
                  htmlType="button"
                  className="gx-py-0 gx-m-3"
                >
                  Save
                </Button>
              </Spin>
            )}
            {!spinner && (
              <Button
                type="primary"
                htmlType="submit"
                className="gx-py-0 gx-m-3"
              >
                Save
              </Button>
            )}
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default FAQForm;
